// src/context/SocketContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { io } from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";
import { setUnreadCountData } from "../store/unreadCount/unreadCountSlice";
import { getAllEmployees } from "service/chatServices";

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const socket = useRef(null);
  const [liveEmployeeList, setLiveEmployeeList] = useState([]);
  const loginUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(process.env.REACT_APP_BACKEND_URL); // Get the server URL
    const ip = `${url.protocol}//${url.host}`; // Extract the base URL

    socket.current = io(ip, {
      transports: ["websocket"],
      reconnectionAttempts: 5,
      timeout: 20000,
    });

    socket.current.on("connect_error", (err) => {
      console.error("WebSocket Error:", err.message);
    });

    if (loginUser?.id) {
      socket.current.emit("register", loginUser.id);
    }

    // Fetch all employees and update unread count
    const fetchEmployees = async () => {
      try {
        const response = await getAllEmployees(loginUser?.id);
        if (response?.data) {
          const totalUnread = response.data.reduce((acc, user) => {
            return acc + (user.unreadCount || 0);
          }, 0);
          dispatch(setUnreadCountData(totalUnread));
          setLiveEmployeeList(response.data);
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();

    socket.current.on("dataUpdate", (data) => {
      if (data?.userId === loginUser?.id) {
        const totalUnread = data?.data?.reduce((acc, user) => {
          return acc + (user.unreadCount || 0);
        }, 0);
        dispatch(setUnreadCountData(totalUnread));
        setLiveEmployeeList(data?.data);
      }
    });

    return () => {
      if (socket.current) {
        socket.current.off("dataUpdate");
        socket.current.disconnect();
      }
    };
  }, [loginUser?.id, dispatch]);

  return (
    <SocketContext.Provider value={{ liveEmployeeList }}>
      {children}
    </SocketContext.Provider>
  );
};
