import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import "./locales";
import "./App.css";
import {
  onMessageListener,
  requestPermission,
} from "helper/notificationService";
import { SocketProvider } from "helper/SocketContext";


function App() {
  useEffect(() => {
    // Request notification permission on load

    requestPermission();

    // Listen for foreground notifications
    onMessageListener();
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <SocketProvider>
        <BrowserRouter history={history}>
          <Theme>
            <Layout />
          </Theme>
        </BrowserRouter>
        </SocketProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
