import api from "service/api";

export function storeChats(data) {
  return api.post(`/store-chat`, data);
}

export function getChats(user1Id, user2Id) {
  return api.get(`/get-chat?user1Id=${user1Id}&user2Id=${user2Id}`);
}

export function deleteChatMessage(user1Id, user2Id, id) {
  return api.put(
    `/delete-chat?user1Id=${user1Id}&user2Id=${user2Id}&chatId=${id}`
  );
}

export function getAllEmployees(userId) {
  return api.get(`/get-all-employees/${userId?userId:""}`);
}

export function markAsRead(payload) {
  return api.post(`/mark-as-read`, payload);
}