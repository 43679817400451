import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
  unreadCountData: 0, // Default to an empty array
};

export const unreadCountSlice = createSlice({
  name: "unreadCount",
  initialState,
  reducers: {
    setUnreadCountData: (state, action) => {
      state.unreadCountData = action.payload;
    },
  },
});

export const { setUnreadCountData } = unreadCountSlice.actions;

export default unreadCountSlice.reducer;
