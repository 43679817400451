import { messaging } from "../firebase";
import { getToken, onMessage } from "firebase/messaging";
import { addDeviceToken } from "../service/employeeService";

// Request Notification Permission and Get Token
export const requestPermission = async () => {
  try {
    // Request notification permission from the user
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.warn("Notification permission not granted.");
      return;
    }

    console.log("Notification permission granted.");

    const adminData = localStorage.getItem("admin");
    let userId;
    if (adminData) {
      try {
        const parsedData = JSON.parse(adminData);
        const userData = JSON.parse(parsedData?.auth)?.user;
        userId = userData?.id;
      } catch (error) {
        console.error("Error parsing localStorage data:", error);
      }
    } else {
      console.warn("No admin data found in localStorage.");
    }

    const storedTokenData = JSON.parse(localStorage.getItem("fcmTokenData"));
    const isTokenValid =
      storedTokenData && new Date(storedTokenData.expiry) > new Date();

    if (isTokenValid) {
      await sendTokenToServer(userId, storedTokenData.token);
    } else {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });

      if (token) {
        const tokenData = {
          token,
          expiry: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        };
        localStorage.setItem("fcmTokenData", JSON.stringify(tokenData));
        await sendTokenToServer(userId, token);
      } else {
        console.warn(
          "No registration token available. Request permission to generate one."
        );
      }
    }
  } catch (error) {
    console.error("An error occurred while retrieving token:", error);
  }
};

// Send Token to Server
const sendTokenToServer = async (userId, token) => {
  try {
    // const response = await fetch(`/api/users-devicetoken/${userId}`, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ token }),
    // });
    const response = await addDeviceToken(userId, { deviceToken: token });

    if (response.success) {
    } else {
      console.error("Failed to send token to server:", response.statusText);
    }
  } catch (error) {
    console.error("Error sending token to server:", error);
  }
};

// Listener for Incoming Messages

export const onMessageListener = () => {
  onMessage(messaging, (payload) => {
    // Display a notification for incoming messages
    const { title, body, icon } = payload.notification;

    function cleanBody(htmlString) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      return doc.body.textContent.trim(); // Extracts only readable text
    }

    // Show notification using the Notification API
    new Notification(title || "New Message", {
      body: cleanBody(body) || "You have a new notification.",
      icon: icon || "/default-icon.png",
    });
  });
};
